import React from "react";

import Badge from "./Badges";

interface IBadgeInterface {
  value?: string | number;
  array?: Array<any>;
}

export const ProductTag: React.FC<IBadgeInterface> = ({ value, array }) => {
  const BadgeReturn: any = [];

  const badge: any = {
    1: (
      <Badge key={1} color="info">
        Nacional
      </Badge>
    ),
    2: (
      <Badge key={2} color="purple">
        CCA Eletrônica
      </Badge>
    ),
    3: (
      <Badge key={3} color="dark">
        Fora de Linha
      </Badge>
    ),
    4: (
      <Badge key={4} color="success">
        Em Breve
      </Badge>
    ),
    5: (
      <Badge key={5} color="danger">
        Promoção
      </Badge>
    ),
    6: (
      <Badge key={6} color="indigo">
        Campanha Promocional
      </Badge>
    ),
    7: (
      <Badge key={7} color="green">
        Chave Montada
      </Badge>
    ),
    9: (
      <Badge key={8} color="success">
        Produto Novo
      </Badge>
    ),
    10: (
      <Badge key={10} color="success">
        Condição Especial Mult.
      </Badge>
    ),
  };

  if (array) {
    const newArray = array.reduce(
      (unique, item) =>
        unique.includes(item.tag) ? unique : [...unique, item.tag],
      []
    );

    newArray.map((item: any) => {
      return BadgeReturn.push(badge[item]);
    });

    return BadgeReturn;
  }

  if (value) {
    return badge[Number(value)] || null;
  }

  return null;
};

export const ProductDiscountTag: React.FC<{ value: boolean }> = ({ value }) => {
  if (value) {
    return <Badge color="danger">Desc. Acima da Política</Badge>;
  }

  return null;
};

export const ProductNoStockTag: React.FC<{
  value: number;
  stock: number;
  amount?: number;
}> = ({ value, stock, amount }) => {
  if (value === 1) {
    if (amount! > stock) {
      return <Badge color="warning">Sob Encomenda</Badge>;
    }
  }

  if (stock <= 0) {
    return <Badge color="warning">Sem Estoque</Badge>;
  }

  if (amount! > stock) {
    return <Badge color="warning">Estoque Insuficiente</Badge>;
  }

  return null;
};
