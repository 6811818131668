import React from "react";

import { formatPrice } from "@utils/formatPrice";

import { IOrder } from "@interfaces/order";

interface IInvoicePriceDetail {
  order: IOrder;
}

const InvoiceNote: React.FC<IInvoicePriceDetail> = ({ order }) => {
  const handleReplace = (note: string) => {
    let stringReplace = "";

    if (note) {
      stringReplace = note.replace(
        "[SUFRAMA]",
        `${formatPrice(order.desconto_suframa)}`
      );
      stringReplace = stringReplace.replace(
        "[PIS]",
        `${formatPrice(order.total_pis)}`
      );
      stringReplace = stringReplace.replace(
        "[COFINS]",
        `${formatPrice(order.total_cofins)}`
      );
      stringReplace = stringReplace.replace(
        "[ICMS]",
        `${formatPrice(order.total_credit_icms)}`
      );
    }

    return <div>{stringReplace}</div>;
  };

  return (
    <>
      <span className="fw-500 fs-16px">Observações</span>
      <br />
      {order.obs ? `* ${order.obs}` : ""}
      {handleReplace(
        order.client
          ? order.client.taxation_type.destaque_imposto &&
              `* ${order.client.taxation_type.destaque_imposto}`
          : ""
      )}
      <br />
      {order.empresa === 0 && order.total_ipi}
      <br />
      <ul className="fs-10px ps-3">
        <li>
          Liberação de crédito mediante análise e aprovação do departamento
          financeiro.
        </li>
        <li>Validade da proposta de sete dias corridos.</li>
        <li>
          Garantia de doze meses, contados a partir da data do faturamento.
        </li>
        <li>
          Em caso de confirmação de pedido, conferir se os itens, quantidades,
          preços, impostos e tributação estão de acordo, pois não aceitaremos
          reclamações posteriores.
        </li>
        <li>
          Não aceitamos devoluções sem uma prévia e formal autorização do
          departamento de vendas, ao qual estabelecerá as condições de
          devolução.
        </li>
      </ul>
    </>
  );
};

export default InvoiceNote;
