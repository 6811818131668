import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { roundNumber } from "@utils/roundNumber";
import { formatPrice } from "@brainylab/brazilian-formats";

import { IOrder } from "@interfaces/order";
import { IInvoice } from "@interfaces/invoice";

interface IHeaderPDF {
  order: IOrder;
  cubic: number;
  invoice: IInvoice;
}

export const InvoicePDFPriceDetail: React.FC<IHeaderPDF> = ({
  order,
  cubic,
  invoice,
}) => {
  const isBudgetOrOrder = [0, 6].includes(order.status)
    ? " Orçamento"
    : " Pedido";

  const isCompanySelected = order.empresa !== 0;

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        marginTop: 15,
        width: "100%",
      }}
    >
      <View style={{ flex: 1, marginRight: 10 }}>
        <View>
          <Text style={{ fontSize: 10 }}>
            Informações do
            {isBudgetOrOrder}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderTopWidth: 0.4,
            borderTopColor: "#383838",
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={{ marginTop: 5 }}>Forma de Pagamento</Text>
          <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
            <Text>{order.payment_form && order.payment_form.name}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderTopWidth: 0.4,
            borderTopColor: "#383838",
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={{ marginTop: 5 }}>Tipo de Frete</Text>
          <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
            <Text>{order.shipping_type && order.shipping_type.name}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderTopWidth: 0.4,
            borderTopColor: "#383838",
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={{ marginTop: 5 }}>Transportadora</Text>

          <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
            <Text>
              {order.shipping_company && order.shipping_company.razao_social}
              {` | `}
              {order.shipping_company
                ? `${order.shipping_company.telefone} /
            ${order.shipping_company.telefone2}`
                : ""}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderTopWidth: 0.4,
            borderTopColor: "#383838",
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={{ marginTop: 5 }}>Volumetria</Text>
          <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
            <Text>{`${roundNumber(cubic, 3)} cm³ / ${roundNumber(
              cubic / 1000000,
              4
            )}mt³`}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderTopWidth: 0.4,
            borderTopColor: "#383838",
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={{ marginTop: 5 }}>
            Peso Total do
            {isBudgetOrOrder}
          </Text>

          <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
            <Text>{`${order.total_weight} Kg`}</Text>
          </View>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ fontSize: 10 }}>Total do {isBudgetOrOrder}</Text>
        <View
          style={{
            flexDirection: "row",
            borderTopWidth: 0.4,
            borderTopColor: "#383838",
            textAlign: "center",
            marginTop: 5,
          }}
        >
          <Text style={{ marginTop: 5 }}>Total Líquido</Text>
          <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
            <Text>{formatPrice(order.valor_produtos)}</Text>
          </View>
        </View>
        {isCompanySelected && (
          <View
            style={{
              flexDirection: "row",
              borderTopWidth: 0.4,
              borderTopColor: "#383838",
              textAlign: "center",
              marginTop: 5,
            }}
          >
            <Text style={{ marginTop: 5 }}>Total IPI</Text>
            <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
              <Text>
                {order.empresa === 3 ? "Isento" : formatPrice(order.total_ipi)}
              </Text>
            </View>
          </View>
        )}

        {order.desconto_suframa > 0 && (
          <View
            style={{
              flexDirection: "row",
              borderTopWidth: 0.4,
              borderTopColor: "#383838",
              textAlign: "center",
              marginTop: 5,
            }}
          >
            <Text style={{ marginTop: 5 }}>Total Desconto Suframa</Text>
            <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
              <Text>{formatPrice(order.desconto_suframa)}</Text>
            </View>
          </View>
        )}

        <View
          style={{
            flexDirection: "row",
            borderTopWidth: 0.4,
            borderColor: "#383838",
            textAlign: "center",
            color: "black",
            marginTop: 5,
            fontSize: 8,
          }}
        >
          <Text style={{ marginTop: 5 }}>Total do {isBudgetOrOrder}</Text>
          <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
            <Text>{formatPrice(order.valor_total)}</Text>
          </View>
        </View>
        {isCompanySelected && (
          <View
            style={{
              flexDirection: "row",
              borderTopWidth: 0.4,
              borderTopColor: "#383838",
              textAlign: "center",
              marginTop: 5,
            }}
          >
            <Text style={{ marginTop: 5 }}>Crédito de ICMS</Text>
            <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
              <Text>
                {order.empresa === 3
                  ? "Isento"
                  : formatPrice(order.total_credit_icms)}
              </Text>
            </View>
          </View>
        )}

        {invoice.hide_estimate_st ||
          order.empresa === 3 ||
          (isCompanySelected && (
            <View
              style={{
                flexDirection: "row",
                borderTopWidth: 0.4,
                borderTopColor: "#383838",
                textAlign: "center",
                marginTop: 5,
              }}
            >
              <Text style={{ marginTop: 5 }}>Estimativa Total ICMS S.T</Text>
              <View style={{ flex: 1, alignItems: "flex-end", marginTop: 5 }}>
                <Text>{formatPrice(order.total_st)}</Text>
              </View>
            </View>
          ))}
      </View>
    </View>
  );
};
