export function roundNumber(
  value: string | number | undefined,
  decimals: number
) {
  if (value) {
    let newValue: number;

    if (typeof value === "string") {
      newValue = parseFloat(value);
    } else {
      newValue = value;
    }

    const fixedValue = newValue.toFixed(decimals);

    return Number(fixedValue);
  }

  return undefined;
}
