import React from "react";

import { Document, Page, StyleSheet } from "@react-pdf/renderer";

import { HeaderPDF } from "./InvoicePDFHeader";
import { InvoicePDFItems } from "./InvoicePDFItems";
import { InvoicePDFPriceDetail } from "./InvoicePDFPriceDetail";
import { InvoicePDFFooter } from "./InvoicePDFFooter";

import { IOrder, IOrderItems } from "@interfaces/order";
import { IInvoice } from "@interfaces/invoice";

interface IInvoicePDF {
  order: IOrder;
  items: Array<IOrderItems>;
  cubic: number;
  invoice: IInvoice;
}

export const InvoicePDF: React.FC<IInvoicePDF> = ({
  order,
  items,
  cubic,
  invoice,
}) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      padding: 10,
      fontSize: 7,
      color: "#383838",
      height: "100%",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <HeaderPDF order={order} />
        <InvoicePDFItems order={order} items={items} invoice={invoice} />
        <InvoicePDFPriceDetail order={order} cubic={cubic} invoice={invoice} />
        <InvoicePDFFooter order={order} />
      </Page>
    </Document>
  );
};
