import React from "react";

import { useRoutes } from "react-router-dom";

import { InvoicesRoutes } from "@modules/invoices/invoices.routes";

export const AppRoutes: React.FC = () => {
  const routes = useRoutes([...InvoicesRoutes]);
  return routes;
};
