/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { parseISO, formatRelative } from "date-fns";
import { format as formatDate, zonedTimeToUtc } from "date-fns-tz";
import { ptBR } from "date-fns/locale";

import { getStatus } from "@modules/invoices/utils/getStatus";

import api from "@services/api";

import { IOrder, IOrderStatus } from "@interfaces/order";

interface ITimeLine {
  isId: number | null;
  isHash: string | null;
  isOpen: boolean;
  order: IOrder | null;
  toggle(): void;
}

interface ITimeLineItems {
  id: number;
  title: string;
  color: string;
  description: string;
  relativeDate: string;
  relativeTime: string;
  formattedDate: string;
}

export const ModalTimeLine: React.FC<ITimeLine> = ({
  isId,
  isHash,
  isOpen,
  toggle,
}) => {
  const [timeLine, setTimeLine] = useState<ITimeLineItems[]>(
    [] as Array<ITimeLineItems>
  );

  useEffect(() => {
    let isMounted = true;

    async function requestData() {
      const { data } = await api.get<IOrderStatus[]>(
        `/orders/status/client/${isId}?hash=${isHash}`
      );

      const nowDate = new Date();

      const timeline = data.map<ITimeLineItems>((item) => {
        const relativeDate = formatRelative(
          zonedTimeToUtc(parseISO(item.created_at), "America/Sao_Paulo"),
          nowDate,
          {
            locale: ptBR,
          }
        );

        const relativeTime = formatDate(parseISO(item.created_at), "HH:mm", {
          locale: ptBR,
          timeZone: "America/Sao_Paulo",
        });

        const formattedDate = formatDate(
          parseISO(item.created_at),
          "dd 'de' MMMM 'de' yyyy",
          {
            locale: ptBR,
            timeZone: "America/Sao_Paulo",
          }
        );

        const formattedDateTime = formatDate(
          parseISO(item.created_at),
          "dd 'de' MMMM 'de' yyyy' às ' HH:mm",
          {
            locale: ptBR,
            timeZone: "America/Sao_Paulo",
          }
        );

        const { title, description, color } = getStatus({
          status: item.status,
          userName: item.user?.nome,
          dateFormatted: formattedDateTime,
          nf: item?.nf,
        });

        return {
          id: item.id,
          title,
          color,
          description,
          relativeDate,
          relativeTime,
          formattedDate,
        };
      });

      if (isMounted) {
        setTimeLine(timeline);
      }
    }

    if (isId && isOpen) {
      requestData();
    }

    return () => {
      isMounted = false;
    };
  }, [isId, isOpen, isHash]);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>TimeLine do Pedido</ModalHeader>
      <ModalBody>
        <div className="timeline mb-3">
          {timeLine.map((item) => (
            <div key={item.id} className="timeline-item">
              <div className="timeline-time">
                <span className="date">{item.relativeDate}</span>
                <span className="time">{item.relativeTime}</span>
              </div>
              <div className="timeline-icon">
                <a className={`bg-${item.color}`} />
              </div>
              <div className="timeline-content">
                <div className="timeline-header pb-1">
                  <div className="username">
                    {item.title}
                    <small />
                  </div>
                  <div className="pull-right text-muted">
                    {item.formattedDate}
                  </div>
                </div>
                <div className="timeline-body">{item.description}</div>
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};
