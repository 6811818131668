import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AppRoutes } from "@routes/AppRoutes";

export const App: React.FC = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className="app">
          <AppRoutes />
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
};
