import React from "react";

interface IRowDetail {
  children: React.ReactNode;
  title: string;
  bold?: boolean;
  paddingText?: "left" | "right";
  hide?: boolean;
}

export const RowDetail: React.FC<IRowDetail> = ({
  children,
  title,
  bold = false,
  paddingText = "right",
  hide = false,
}) => {
  return (
    <tr hidden={hide}>
      <td className={bold ? "fw-700" : ""}>{title}</td>
      <td className={`text-${paddingText} ${bold ? "fw-700" : ""}`}>
        {children}
      </td>
    </tr>
  );
};
