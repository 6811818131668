import React from "react";
import { Row, Col } from "reactstrap";

import { roundNumber } from "@utils/roundNumber";

import { IInvoice } from "@interfaces/invoice";
import { IOrder } from "@interfaces/order";

import { formatPrice } from "../../../utils/formatPrice";
import { RowDetail } from "./RowDetail";

interface IInvoicePriceDetail {
  order: IOrder;
  invoice: IInvoice;
  cubic: number;
}

const InvoicePriceDetail: React.FC<IInvoicePriceDetail> = ({
  order,
  invoice,
  cubic,
}) => {
  const isCompanySelected = order.empresa === 0;

  return (
    <Row>
      <Col md={7}>
        <p className="lead">
          Informações do
          {order.status === 0 ? " Orçamento" : " Pedido"}
        </p>
        <table className="table align-middle table-sm">
          <tbody>
            <RowDetail title="Forma de Pagamento">
              {order.payment_form && order.payment_form.name}
            </RowDetail>
            <RowDetail title="Tipo de Frete">
              {order.shipping_type && order.shipping_type.name}
            </RowDetail>
            <RowDetail title="Transportadora">
              {order.shipping_company && order.shipping_company.razao_social}
              {` | `}
              {order.shipping_company
                ? `${order.shipping_company.telefone} /
            ${order.shipping_company.telefone2}`
                : ""}
            </RowDetail>
            <RowDetail title="Volumetria">
              {`${roundNumber(cubic, 3)} cm³ / ${roundNumber(
                cubic / 1000000,
                4
              )}mt³`}
            </RowDetail>
            <RowDetail
              title={`Peso Total do ${
                order.status === 3 ? "Orçamento" : "Pedido"
              }`}
            >
              {`${order.total_weight} Kg`}
            </RowDetail>
          </tbody>
        </table>
      </Col>
      <Col md={5}>
        <p className="lead">
          Total do
          {order.status === 3 ? " Orçamento" : " Pedido"}
        </p>
        <table className="table">
          <tbody>
            <RowDetail title="Total Líquido" hide={isCompanySelected}>
              {formatPrice(order.valor_produtos)}
            </RowDetail>
            <RowDetail title="Total IPI" hide={isCompanySelected}>
              {order.empresa === 3 ? "Isento" : formatPrice(order.total_ipi)}
            </RowDetail>
            {order.desconto_suframa > 0 && (
              <RowDetail
                title="Total Desconto Suframa"
                hide={isCompanySelected}
              >
                {formatPrice(order.desconto_suframa)}
              </RowDetail>
            )}
            {order.valor_frete > 0 && (
              <RowDetail title="Total do Frete">
                {formatPrice(order.valor_frete)}
              </RowDetail>
            )}
            <RowDetail
              title={`Total do ${order.status === 3 ? "Orçamento" : "Pedido"}`}
              bold
            >
              {formatPrice(order.valor_total)}
            </RowDetail>
            <RowDetail title="Crédito de ICMS" hide={isCompanySelected}>
              {order.empresa === 3
                ? "Isento"
                : formatPrice(order.total_credit_icms)}
            </RowDetail>
            <RowDetail
              hide={invoice.hide_estimate_st || isCompanySelected}
              title="Estimativa Total ICMS S.T"
            >
              {formatPrice(order.total_st)}
            </RowDetail>
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default InvoicePriceDetail;
