import React from "react";

export type IBadgeColor =
  | "blue"
  | "default"
  | "primary"
  | "theme"
  | "success"
  | "danger"
  | "warning"
  | "green"
  | "yellow"
  | "info"
  | "purple"
  | "indigo"
  | "dark"
  | "pink"
  | "lime"
  | "secondary"
  | "light";

export interface IBadgesProps {
  children: React.ReactNode;
  type?: "pill" | "square";
  color?: IBadgeColor;
}

const Badges: React.FC<IBadgesProps> = ({
  children,
  type,
  color = "default",
}) => {
  const typeBadge =
    type === "pill" ? "rounded-pill" : type === "square" ? "rounded-0" : "";

  return (
    <span className={`badge ${typeBadge} bg-${color} ms-1 text-white`}>
      {children}
    </span>
  );
};

export default Badges;
