import React from "react";
import { IOrder } from "@interfaces/order";

import { View, Text } from "@react-pdf/renderer";
import { formatPrice } from "@utils/formatPrice";

interface IInvoicePDFNote {
  order: IOrder;
}

export const InvoicePDFNote: React.FC<IInvoicePDFNote> = ({ order }) => {
  const handleReplace = (note: string) => {
    let stringReplace = "";

    if (note) {
      stringReplace = note.replace(
        "[SUFRAMA]",
        `${formatPrice(order.desconto_suframa)}`
      );
      stringReplace = stringReplace.replace(
        "[PIS]",
        `${formatPrice(order.total_pis)}`
      );
      stringReplace = stringReplace.replace(
        "[COFINS]",
        `${formatPrice(order.total_cofins)}`
      );
      stringReplace = stringReplace.replace(
        "[ICMS]",
        `${formatPrice(order.total_credit_icms)}`
      );
    }

    return <div>{stringReplace}</div>;
  };

  return (
    <View
      style={{
        marginBottom: "2px",
      }}
    >
      <Text style={{ marginBottom: "5px", color: "black" }}>Observações: </Text>
      {order.obs !== "" && (
        <Text style={{ marginBottom: "5px", color: "black" }}>{order.obs}</Text>
      )}
      {order.empresa === 0 && (
        <Text style={{ marginBottom: "5px", color: "black" }}>
          {order.total_ipi}
        </Text>
      )}
      <Text style={{ color: "black" }}>
        {handleReplace(
          order.client
            ? order.client.taxation_type.destaque_imposto &&
                `* ${order.client.taxation_type.destaque_imposto}`
            : " "
        )}
      </Text>
      <View style={{ marginBottom: "5px" }}>
        <Text>
          • Liberação de crédito mediante análise e aprovação do departamento
          financeiro.
        </Text>
        <Text>• Validade da proposta de sete dias corridos.</Text>
        <Text>
          • Garantia de doze meses, contados a partir da data do faturamento.
        </Text>
        <Text>
          • Em caso de confirmação de pedido, conferir se os itens, quantidades,
          preços, impostos e tributação estão de acordo, pois não aceitaremos
          reclamações posteriores.
        </Text>
        <Text>
          • Não aceitamos devoluções sem uma prévia e formal autorização do
          departamento de vendas, ao qual estabelecerá as condições de
          devolução.
        </Text>
      </View>
    </View>
  );
};
