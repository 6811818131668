import React, { useState, forwardRef } from "react";
import { Button as CustomButton, Tooltip, ButtonProps } from "reactstrap";

interface IButtonProps extends ButtonProps {
  tooltip?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start"
    | undefined;
  tooltipTitle?: string;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ children, tooltip, tooltipTitle, ...rest }, ref) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const { id } = rest;

    return (
      <>
        <CustomButton innerRef={ref} {...rest}>
          {children}
        </CustomButton>
        {!!tooltip && (
          <Tooltip
            placement={tooltip}
            isOpen={tooltipOpen}
            target={id ?? ""}
            toggle={toggle}
          >
            <span className="f-s-13">{tooltipTitle}</span>
          </Tooltip>
        )}
      </>
    );
  }
);

export default Button;
