import React from "react";
import { Text, View, Link } from "@react-pdf/renderer";

import { formatPrice } from "@brainylab/brazilian-formats";
import { roundNumber } from "@utils/roundNumber";

import { NoStockTag } from "./InvoicePDFTags";

import { IOrder, IOrderItems } from "@interfaces/order";
import { IInvoice } from "@interfaces/invoice";

interface IHeaderPDF {
  order: IOrder;
  items: Array<IOrderItems>;
  invoice: IInvoice;
}

export const InvoicePDFItems: React.FC<IHeaderPDF> = ({
  order,
  items,
  invoice,
}) => {
  const productLink = (productId: number) => {
    if (order.empresa === 3)
      return `https://indipeso.com.br/pages/produtos-detalhe.php?page=produto&id=${productId}`;

    return `https://sibratec.ind.br/pages/produtos-detalhe.php?page=produto&id=${productId}`;
  };

  const isCompanySelected = order.empresa !== 0;

  return (
    <View
      style={{
        flexDirection: "column",
        fontSize: 6,
        marginTop: 15,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          borderBottomWidth: 0.5,
          borderBottomColor: "#383838",
          alignItems: "center",
          height: 15,
          textAlign: "center",
          flexGrow: 1,
        }}
      >
        <Text
          style={{
            width: "3%",
            textAlign: "center",
          }}
        >
          Nº
        </Text>
        <Text
          style={{
            width: "6%",
            textAlign: "center",
          }}
        >
          Ref.
        </Text>
        <Text
          style={{
            textAlign: "left",
            width: "60%",
          }}
        >
          Descrição do Produto
        </Text>
        <Text
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          NCM
        </Text>
        <Text
          style={{
            width: "4%",
            textAlign: "center",
          }}
        >
          Qtde
        </Text>
        {!invoice.hide_desc_political && (
          <Text
            style={{
              width: "5%",
              textAlign: "center",
            }}
          >
            Desc. P(%)
          </Text>
        )}
        {!invoice.hide_desc_treatment && (
          <Text
            style={{
              width: "5%",
              textAlign: "center",
            }}
          >
            Desc. T(%)
          </Text>
        )}
        {isCompanySelected && (
          <Text
            style={{
              width: "10%",
              textAlign: "center",
            }}
          >
            V. Unit.
          </Text>
        )}
        {(isCompanySelected || order.empresa === 3) && (
          <Text
            style={{
              width: "6%",
              textAlign: "center",
            }}
          >
            IPI(%)
          </Text>
        )}
        {invoice.hide_estimate_st ||
          order.empresa === 3 ||
          (isCompanySelected && (
            <Text
              style={{
                width: "7%",
                textAlign: "center",
              }}
            >
              Est.ST.(%)
            </Text>
          ))}
        {(order.empresa === 3 || isCompanySelected) && (
          <Text
            style={{
              width: "6%",
              textAlign: "center",
            }}
          >
            ICMS.(%)
          </Text>
        )}
        {!(order.desconto_suframa > 0) ||
          order.empresa === 3 ||
          (isCompanySelected && (
            <Text
              style={{
                width: "6%",
                textAlign: "center",
              }}
            >
              Desc. Suframa
            </Text>
          ))}
        {(!invoice.hide_total_liquid_ipi || order.empresa === 3) && (
          <Text
            style={{
              width: "12%",
              textAlign: "center",
            }}
          >
            Val. L. + IPI
          </Text>
        )}
        <Text
          style={{
            width: "12%",
            textAlign: "center",
          }}
        >
          Total Bruto
        </Text>
      </View>
      {items.map((item, i: number) => (
        <View
          key={i}
          style={{
            flexDirection: "row",
            borderBottomColor: "#383838",
            borderBottomWidth: 0.2,
            alignItems: "center",
            height: 15,
          }}
        >
          <Text
            style={{
              width: "3%",
              textAlign: "center",
            }}
          >
            {i + 1}
          </Text>
          <Link
            src={productLink(item.product.id)}
            style={{
              width: "6%",
              textAlign: "center",
              textDecoration: "none",
              color: "black",
            }}
          >
            <Text>{item.product.referencia}</Text>
          </Link>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              width: "60%",
              textAlign: "left",
            }}
          >
            <Link
              src={productLink(item.product.id)}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Text>{item.product.nome}</Text>
            </Link>
            <NoStockTag
              value={item.product.permitirsemestoque}
              stock={item.product.stock_limited}
              amount={item.qtde}
            />
          </View>
          <Text style={{ width: "10%", textAlign: "center" }}>
            {item.product.ncm}
          </Text>
          <Text style={{ width: "4%", textAlign: "center" }}>
            {Number(item.qtde)}
          </Text>
          {!invoice.hide_desc_political && (
            <Text style={{ width: "5%", textAlign: "center" }}>
              {`${item.descontop}%`}
            </Text>
          )}
          {!invoice.hide_desc_treatment && (
            <Text style={{ width: "5%", textAlign: "center" }}>
              {`${item.descontot}%`}
            </Text>
          )}
          {isCompanySelected && (
            <Text style={{ width: "10%", textAlign: "center" }}>
              {formatPrice(item.valor_unitario)}
            </Text>
          )}
          {(order.empresa === 3 || isCompanySelected) && (
            <Text style={{ width: "6%", textAlign: "center" }}>{`${Number(
              item.percentual_ipi
            )}%`}</Text>
          )}
          {invoice.hide_estimate_st ||
            order.empresa === 3 ||
            (isCompanySelected && (
              <Text style={{ width: "7%", textAlign: "center" }}>{`${Number(
                item.percent_icms_st
              )}%`}</Text>
            ))}
          {(order.empresa === 3 || isCompanySelected) && (
            <Text style={{ width: "6%", textAlign: "center" }}>{`${roundNumber(
              item.percent_icms,
              0
            )}%`}</Text>
          )}
          {!(order.desconto_suframa > 0) ||
            order.empresa === 3 ||
            (isCompanySelected && (
              <Text style={{ width: "6%", textAlign: "center" }}>
                {formatPrice(item.desconto_suframa)}
              </Text>
            ))}
          {(!invoice.hide_total_liquid_ipi || order.empresa === 3) && (
            <Text style={{ width: "12%", textAlign: "center" }}>
              {formatPrice(item.price_liquid_ipi)}
            </Text>
          )}

          <Text style={{ width: "12%", textAlign: "center" }}>
            {formatPrice(item.price_total_tax)}
          </Text>
        </View>
      ))}
    </View>
  );
};
