import React from "react";
import { formatCnpjCpf } from "@brainylab/brazilian-formats";
import { format, parseISO } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

import { View, Text, Image } from "@react-pdf/renderer";

import logoSibratec from "../../../assets/logo/sibratec-pdf.png";
import logoJva from "../../../assets/logo/jva-pdf.png";
import logoIndipeso from "../../../assets/logo/indipeso-pdf.png";

import { IOrder } from "@interfaces/order";

interface IHeaderPDF {
  order: IOrder;
}

export const HeaderPDF: React.FC<IHeaderPDF> = ({ order }) => {
  const _logoSibratec = {
    width: 150,
    logo: logoSibratec,
  };
  const _logoJva = {
    width: 150,
    logo: logoJva,
  };
  const _logoIndiPeso = {
    width: 100,
    logo: logoIndipeso,
  };

  const logo: { [key: number]: { width: number; logo: string } } = {
    0: _logoSibratec,
    1: _logoSibratec,
    2: _logoJva,
    3: _logoIndiPeso,
  };

  const isComplement = false;
  // order.complement_order;

  return (
    <View style={{ flexDirection: "row" }}>
      <View style={{ flex: 1, alignItems: "flex-start" }}>
        <Image
          style={{ width: logo[order.empresa].width }}
          source={logo[order.empresa].logo}
          cache
        />
        <Text
          style={{
            marginTop: 5,
            color: "black",
          }}
        >
          {order.company && order.company.name}
        </Text>
        <Text>
          {order.company && order.company.address}
          {`, `}
          {order.company && order.company.number}
          {` - `}
          {order.company && order.company.neighborhood}
        </Text>
        <Text>
          {order.company && order.company.city}
          {` - `}
          {order.company && order.company.district}
          {` - `}
          {order.company && order.company.country}
          {`. CEP: `}
          {order.company && order.company.zipcode}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Telefone: </View>
          {order.company &&
            `+55 ${order.company.telephone} / ${order.company.second_telephone}`}
        </Text>
        <Text>
          <View style={{ color: "black" }}>CNPJ: </View>
          {order.company && formatCnpjCpf(String(order.company.cnpj))}
        </Text>
        <Text style={{ marginTop: 2 }}>
          <View style={{ color: "black" }}>Representante: </View>
          {order.sales_man ? order.sales_man.nome : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Email: </View>
          {order.sales_man ? order.sales_man.email : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Telefone: </View>
          {order.sales_man ? order.sales_man.telefone : ""} /{" "}
          <View style={{ color: "black" }}>Whatsapp: </View>
          {order.sales_man ? order.sales_man.telefone : ""}
        </Text>
      </View>
      <View style={{ flex: 1, alignItems: "flex-end" }}>
        <Text
          style={{
            marginTop: 7,
            fontSize: 9,
            textTransform: "uppercase",
            color: "black",
          }}
        >
          {order.updated_at
            ? format(
                parseISO(String(order.updated_at)),
                " d 'de' MMMM 'de' yyyy",
                {
                  locale: ptBr,
                }
              )
            : order.data_alt}
        </Text>
        <Text
          style={{
            marginTop: 5,
            marginBottom: 3,
            fontSize: 8,
            textTransform: "uppercase",
            color: "black",
          }}
        >
          {`${order.status === 0 ? "ORÇAMENTO" : "PEDIDO"} ${
            isComplement ? "COMPLEMENTO" : ""
          } - ${order.id}`}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Cliente: </View>
          {order.client ? `${order.client.codigo} - ${order.client.razao}` : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Responsável: </View>
          {order.client ? order.client.responsavel : ""} /
          <View style={{ color: "black" }}> E-mail: </View>
          {order.client ? order.client.email : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Cidade: </View>
          {order.client ? order.client.cidade : ""} /{" "}
          <View style={{ color: "black" }}>Estado: </View>
          {order.client ? order.client.district.name : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Endereço: </View>
          {order.client ? order.client.endereco : ""} /{" "}
          <View style={{ color: "black" }}>Nº: </View>
          {order.client ? order.client.numero : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Bairro: </View>
          {order.client ? order.client.bairro : ""} /{" "}
          <View style={{ color: "black" }}>CEP: </View>
          {order.client ? order.client.cep : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>CNPJ: </View>
          {order.client ? formatCnpjCpf(order.client.cnpj) : ""} /{" "}
          <View style={{ color: "black" }}>IE: </View>
          {order.client ? order.client.ie : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Telefone: </View>
          {order.client ? order.client.telefone : ""}
          {order.client ? ` / ${order.client.telefone2}` : ""}
        </Text>
        <Text>
          <View style={{ color: "black" }}>Tipo de Tributação: </View>
          {order.client ? order.client.taxation_type.descricao : ""}
        </Text>
      </View>
    </View>
  );
};
