import ReactDOM from "react-dom";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./index.css";
import "./scss/react.scss";

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
