import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardFooter } from "reactstrap";

import Swal from "sweetalert2";

import InvoiceDetail from "@modules/invoices/components/InvoiceDetail";
import InvoiceFooter from "@modules/invoices/components/InvoiceFooter";
import InvoiceItem from "@modules/invoices/components/InvoiceItem";
import InvoiceNote from "@modules/invoices/components/InvoiceNote";
import InvoicePriceDetail from "@modules/invoices/components/InvoicePriceDetail";
import { ModalTimeLine } from "@modules/invoices/components/ModalTimeLine";
import { ModalCreatePDF } from "@modules/invoices/components/ModalCreatePDF";

import { Button } from "@components/Button";

import api from "@services/api";
import { calculateStock } from "@utils/calculateStock";

import { IInvoice } from "@interfaces/invoice";
import { IOrder, IOrderItems } from "@interfaces/order";

const Invoice: React.FC = () => {
  const [invoice, setInvoice] = useState<IInvoice>({} as IInvoice);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [items, setItems] = useState<IOrderItems[]>([] as IOrderItems[]);
  const [cubic, setCubic] = useState(0);
  const [createPdf, setCreatePdf] = useState(false);
  const [isModalTimeLine, setModalTimeLine] = useState(false);
  const [load, setLoad] = useState(true);

  const { key } = useParams<{ key: string }>();

  window.location.href = `https://sibratec.ind.br/client/order-view/${key}`;

  const handleCreatePDF = () => {
    Swal.fire({
      title: "Criando PDF...",
      html: "Criando Arquivo PDF para Impressão ou Download.",
    });
    Swal.showLoading();
    setCreatePdf(true);
  };

  useEffect(() => {
    let isMounted = true;

    async function requestData() {
      try {
        if (!key) {
          throw Error;
        }

        const { data } = await api.get(`/invoices/client?hash=${key}`);
        let totalCubic = 0;

        data.items.map((item: any) => {
          item.product.stock_limited = calculateStock({
            stocks: item.product.stocks,
            company: data.order.empresa,
            limitedStock: true,
          });

          const height = item.product.sizes ? item.product.sizes.height : 0;
          const width = item.product.sizes ? item.product.sizes.width : 0;
          const depth = item.product.sizes ? item.product.sizes.depth : 0;

          const someSizes = height * width * depth;

          const someCubic = someSizes * Number(item.qtde);

          totalCubic += someCubic;
          return null;
        });

        if (isMounted) {
          setOrder(data.order);
          setInvoice(data.invoice);
          setItems(data.items);
          setCubic(totalCubic);
          setLoad(false);
        }

        document.title = `${data.order.status === 0 ? "OR" : "PD"}-${
          data.order.id
        } - ${data.order.client ? data.order.client.razao : ""}`;
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Erro ao Carregar o Pedido...",
          text: "O Link do seu Pedido não é Válido, entre em contato com o seu Representante...",
          allowEnterKey: false,
          showConfirmButton: false,
          allowOutsideClick: false,
        });
      }
    }

    requestData();

    return () => {
      isMounted = false;
    };
  }, [key]);

  if (load) return null;

  return (
    <div className="p-3">
      <ModalCreatePDF
        order={order}
        items={items}
        cubic={cubic}
        invoice={invoice}
        isOpen={createPdf}
        toggle={() => setCreatePdf(false)}
        onOpened={() => {
          Swal.close();
        }}
      />
      <ModalTimeLine
        isId={order.id}
        isHash={order.hash}
        isOpen={isModalTimeLine}
        order={order}
        toggle={() => setModalTimeLine((prevState) => !prevState)}
      />
      <Card className="mb-3 bg-dark">
        <CardBody className="d-flex justify-content-end">
          <Button
            size="sm"
            color="secondary"
            id="timeline"
            className="ms-1"
            tooltip="bottom-end"
            tooltipTitle="TimeLine do Pedido"
            onClick={() => setModalTimeLine((prevState) => !prevState)}
          >
            <i className="fa fa-code-branch t-plus-1 fa-fw fa-lg" />
          </Button>
          <Button
            size="sm"
            color="secondary"
            className="ms-1"
            onClick={() => handleCreatePDF()}
            id="print"
            tooltip="bottom-end"
            tooltipTitle="Imprimir"
          >
            <i className="fa fa-file-pdf t-plus-1 fa-fw fa-lg" />
          </Button>
        </CardBody>
      </Card>

      <Card className="fs-13px">
        <CardBody>
          <InvoiceDetail order={order} />
          <InvoiceItem items={items} invoice={invoice} order={order} />
          <InvoicePriceDetail order={order} invoice={invoice} cubic={cubic} />
          <InvoiceNote order={order} />
        </CardBody>
        <CardFooter>
          <InvoiceFooter order={order} />
        </CardFooter>
      </Card>
    </div>
  );
};

export default Invoice;
