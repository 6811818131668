import { format, parseISO } from "date-fns";

import { IBadgeColor } from "@components/Badges";

import { IOrderNF } from "@interfaces/order";

interface IOption {
  color: IBadgeColor;
  badgeTitle: string;
  title: string;
  description: string;
}

interface IStatusOptions {
  [key: number]: IOption;
}

interface IStatusProps {
  status: number | string;
  userName?: string;
  dateFormatted?: string;
  nf?: IOrderNF;
  orderComplement?: number | null;
}

export function getStatus({
  status,
  userName = "",
  dateFormatted = "",
  nf,
  orderComplement,
}: IStatusProps): IOption {
  const statusOptions: IStatusOptions = {
    0: {
      color: "success",
      badgeTitle: "Principal",
      title: "Orçamento Principal",
      description: `Orçamento Principal criado por ${userName} em ${dateFormatted}`,
    },
    1: {
      color: "secondary",
      badgeTitle: "Enviado Faturador",
      title: "Transformado em Pedido",
      description: `Orçamento transformado em pedido por ${userName} e aguardando análise do faturador em ${dateFormatted}`,
    },
    2: {
      color: "danger",
      badgeTitle: "Reprovado",
      title: "Pedido Reprovado",
      description: `Pedido reprovado por ${userName} em ${dateFormatted}`,
    },
    3: {
      color: "green",
      badgeTitle: "Edição Complementar",
      title: "Pedido em Edição Complementar",
      description: `Pedido em edição complementar, alteração efetivada por ${userName} em ${dateFormatted}`,
    },
    4: {
      color: "secondary",
      badgeTitle: "Pedido E-commerce",
      title: "Pedido E-commerce",
      description: `Pedido feito pelo e-commerce em ${dateFormatted}`,
    },
    5: {
      color: "success",
      badgeTitle: "Pedido Recebido",
      title: "Pedido Recebido",
      description: `Seu pedido foi recebido pelo nosso sistema interno em ${dateFormatted}`,
    },
    6: {
      color: "warning",
      badgeTitle: "Complementar",
      title: "Orçamento Complementar",
      description: `Orçamento Complementar criado por ${userName} em ${dateFormatted}`,
    },
    7: {
      color: "secondary",
      badgeTitle: "Pedido Complementar",
      title: "Pedido Complementar",
      description: `Orçamento complementar transformado em Pedido complementar por ${userName} e aguardando análise do faturador em ${dateFormatted}`,
    },
    10: {
      color: "indigo",
      badgeTitle: "Análise Financeiro",
      title: "Enviado para Análise do Financeiro",
      description: `Pedido está sendo analisado pelo financeiro, ${dateFormatted}`,
    },
    11: {
      color: "danger",
      badgeTitle: "Cancelado",
      title: "Recusado pelo Financeiro",
      description: `Pedido Recusado pelo setor financeiro em ${dateFormatted}`,
    },
    12: {
      color: "indigo",
      badgeTitle: "Análise Ped. Complementar",
      title: "Enviado para Análise do Financeiro",
      description: `Pedido complementar está sendo analisado pelo financeiro, ${dateFormatted}`,
    },
    13: {
      color: "indigo",
      badgeTitle: "Aguardando Financeiro",
      title: "Aguardando Liberação Financeiro",
      description: `Pedido Aguardando Liberação de Financeiro, ${dateFormatted}`,
    },
    20: {
      color: "warning",
      badgeTitle: "Produção Solicitada",
      title: "Solicitado Produção",
      description: `Pedido tem itens que precisam ser produzidos, ${dateFormatted}`,
    },
    21: {
      color: "info",
      badgeTitle: "Em Produção",
      title: "Em Produção",
      description: `O pedido está em produção, ${dateFormatted}`,
    },
    22: {
      color: "success",
      badgeTitle: "Produção Finalizada",
      title: "Produção Finalizada",
      description: `A produção do pedido foi finalizado em ${dateFormatted}`,
    },
    23: {
      color: "danger",
      badgeTitle: "Produção Cancelada",
      title: "Produção Cancelada",
      description: `pedido cancelado pelo setor de produção em ${dateFormatted}`,
    },
    24: {
      color: "danger",
      badgeTitle: "Produção",
      title: "Aguardando Máteria Prima",
      description: `produção aguardando matéria prima para finalizar a produção, ${dateFormatted}`,
    },
    30: {
      color: "warning",
      badgeTitle: "Separação",
      title: "Enviado para Separação",
      description: `pedido enviado para a separação em ${dateFormatted}`,
    },
    31: {
      color: "info",
      badgeTitle: "Separação",
      title: "Pedido em Separação",
      description: `pedido está em separação pelo setor responsável, ${dateFormatted}`,
    },
    32: {
      color: "success",
      badgeTitle: "Separação",
      title: "Separação Finalizada",
      description: `separação do pedido foi finalizada em ${dateFormatted}`,
    },
    33: {
      color: "danger",
      badgeTitle: "Separação",
      title: "Produto em Falta",
      description: `pedido cancelado por falta de produto em ${dateFormatted}`,
    },
    40: {
      color: "purple",
      badgeTitle: "Aguardando NF-e",
      title: "Aguardando NF-e",
      description: `aguardando a emissão da NF-e, ${dateFormatted}`,
    },
    41: {
      color: "warning",
      badgeTitle: "NF-e Emitida",
      title: "NF-e Emitida",
      description: `NF-e emitida para o pedido, ${dateFormatted} | NF-e: ${nf?.nf_number} - Chave NF-e: ${nf?.nf_key}`,
    },
    42: {
      color: "danger",
      badgeTitle: "NF-e Cancelada",
      title: "NF-e Cancelada",
      description: `NF-e foi cancelada e assim cancelando o pedido, ${dateFormatted}`,
    },
    50: {
      color: "secondary",
      badgeTitle: "Aguardando Coleta",
      title: "Aguardando Coleta",
      description: `aguardando a transportadora coletar o pedido, ${dateFormatted}`,
    },
    51: {
      color: "success",
      badgeTitle: "Pedido Coletado",
      title: "Pedido Coletado",
      description: `pedido coletado pela ${
        nf?.shipping_company.nome_fantasia
      } em ${
        nf?.collected_date
          ? format(parseISO(nf.collected_date), "dd/MM/yyyy HH:mm:ss")
          : null
      }`,
    },
    98: {
      color: "success",
      badgeTitle: "Pedido Complementar",
      title: "Pedido Complementar Finalizado",
      description: `pedido complementar enviado para o sistema interno ${dateFormatted}, Verifique o pedido ${orderComplement} para mais informações`,
    },
    99: {
      color: "info",
      badgeTitle: "Rec. No Sistema Interno",
      title: "Sistema Interno",
      description: `pedido enviado para o sistema interno ${dateFormatted}`,
    },
    100: {
      color: "danger",
      badgeTitle: "Not Found",
      title: "Not Found",
      description: `status não encontrado... aguarde o sistema atualizar os pedidos`,
    },
  } as IStatusOptions;

  const statusOption = statusOptions[Number(status)];

  if (!statusOption) return statusOptions[100];

  return statusOption;
}
