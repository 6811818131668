import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

import { BlobProvider } from "@react-pdf/renderer";
import JSFileDownloader from "js-file-downloader";

import { InvoicePDF } from "./InvoicePDF";

import { IOrder, IOrderItems } from "@interfaces/order";
import { IInvoice } from "@interfaces/invoice";

interface IModalCreatePDF {
  order: IOrder;
  cubic: number;
  items: Array<IOrderItems>;
  invoice: IInvoice;
  isOpen: boolean;
  toggle(): void;
  onOpened(): void;
}

export const ModalCreatePDF: React.FC<IModalCreatePDF> = ({
  order,
  items,
  cubic,
  invoice,
  isOpen,
  toggle,
  onOpened,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" onOpened={onOpened}>
      <ModalHeader toggle={toggle}>Arquivo PDF</ModalHeader>
      <ModalBody className="center">
        {isOpen && (
          <BlobProvider
            document={
              <InvoicePDF
                order={order}
                items={items}
                cubic={cubic}
                invoice={invoice}
              />
            }
          >
            {({ url }) => {
              if (url) {
                return (
                  <div className="d-flex justify-content-center">
                    <Button
                      color="secondary"
                      id="mail"
                      onClick={() => {
                        new JSFileDownloader({
                          url,
                          filename: `${order.id} - ${order.client.razao}.pdf`,
                        });
                      }}
                    >
                      <i className="fa fa-cloud-arrow-down t-plus-1 fa-fw fa-lg" />
                    </Button>
                    <Button
                      color="secondary"
                      id="mail"
                      className="ms-4"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(url, "print", "height=800,width=1200");
                      }}
                    >
                      <i className="fa fa-print t-plus-1 fa-fw fa-lg" />
                    </Button>
                  </div>
                );
              }

              return <div>Gerando Arquivo PDF</div>;
            }}
          </BlobProvider>
        )}
      </ModalBody>
    </Modal>
  );
};
