import React from "react";

import { ProductTag, ProductNoStockTag } from "@components/ProductTags";

import { formatPrice } from "@utils/formatPrice";
import { roundNumber } from "@utils/roundNumber";

import { IInvoice } from "@interfaces/invoice";
import { IOrder, IOrderItems } from "@interfaces/order";

interface IItemInvoice {
  order: IOrder;
  items: Array<IOrderItems>;
  invoice: IInvoice;
}

const InvoiceItem: React.FC<IItemInvoice> = ({ order, items, invoice }) => {
  const isCompanySelected = order.empresa === 0;

  const productLink = (productId: number) => {
    if (order.empresa === 3)
      return `https://indipeso.com.br/pages/produtos-detalhe.php?page=produto&id=${productId}`;

    return `https://sibratec.ind.br/pages/produtos-detalhe.php?page=produto&id=${productId}`;
  };

  return (
    <div className="table-responsive pt-2">
      <table className="table align-middle table-sm table-striped striped-rounded">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>Nº</th>
            <th style={{ textAlign: "center" }}>Ref.</th>
            <th>Nome do Produto</th>
            <th style={{ textAlign: "center" }}>NCM</th>
            <th style={{ textAlign: "center" }}>Qtde</th>
            <th
              hidden={invoice.hide_desc_political}
              style={{ textAlign: "center" }}
            >
              Desc. P(%)
            </th>
            <th
              hidden={invoice.hide_desc_treatment}
              style={{ textAlign: "center" }}
            >
              Desc. T(%)
            </th>
            <th style={{ textAlign: "center" }} hidden={isCompanySelected}>
              V. Unit.
            </th>
            <th
              style={{ textAlign: "center" }}
              hidden={order.empresa === 3 || isCompanySelected}
            >
              IPI(%)
            </th>
            <th
              hidden={
                invoice.hide_estimate_st ||
                order.empresa === 3 ||
                isCompanySelected
              }
              style={{ textAlign: "center" }}
            >
              Est.ST.(%)
            </th>
            <th
              style={{ textAlign: "center" }}
              hidden={order.empresa === 3 || isCompanySelected}
            >
              ICMS.(%)
            </th>
            <th
              style={{ textAlign: "center" }}
              hidden={
                !(order.desconto_suframa > 0) ||
                order.empresa === 3 ||
                isCompanySelected
              }
            >
              Desc. Suframa
            </th>
            <th
              hidden={invoice.hide_total_liquid_ipi || order.empresa === 3}
              style={{ textAlign: "center" }}
            >
              Val. L. + IPI
            </th>
            <th style={{ textAlign: "center" }}>Total Bruto</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: IOrderItems, i: number) => (
            <tr key={item.id}>
              <td style={{ textAlign: "center" }}>{i + 1}</td>
              <td style={{ textAlign: "center" }}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={productLink(item.product.id)}
                >
                  {item.product.referencia}
                </a>
              </td>
              <td>
                {item.product.nome}
                <ProductTag array={item.product.tags} />
                <ProductNoStockTag
                  value={item.product.permitirsemestoque}
                  stock={item.product.stock_limited}
                  amount={item.qtde}
                />
              </td>
              <td style={{ textAlign: "center" }}>{item.product.ncm}</td>
              <td style={{ textAlign: "center" }}>{Number(item.qtde)}</td>
              <td
                hidden={invoice.hide_desc_political}
                style={{ textAlign: "center" }}
              >
                {`${item.descontop}%`}
              </td>
              <td
                hidden={invoice.hide_desc_treatment}
                style={{ textAlign: "center" }}
              >
                {`${item.descontot}%`}
              </td>
              <td style={{ textAlign: "center" }} hidden={isCompanySelected}>
                {formatPrice(item.valor_unitario)}
              </td>
              <td
                style={{ textAlign: "center" }}
                hidden={order.empresa === 3 || isCompanySelected}
              >
                {`${Number(item.percentual_ipi)}%`}
              </td>
              <td
                hidden={
                  invoice.hide_estimate_st ||
                  order.empresa === 3 ||
                  isCompanySelected
                }
                style={{ textAlign: "center" }}
              >
                {`${item.percent_icms_st}%`}
              </td>
              <td
                style={{ textAlign: "center" }}
                hidden={order.empresa === 3 || isCompanySelected}
              >
                {`${roundNumber(item.percent_icms, 0)}%`}
              </td>
              <td
                style={{ textAlign: "center" }}
                hidden={
                  !(order.desconto_suframa > 0) ||
                  order.empresa === 3 ||
                  isCompanySelected
                }
              >
                {formatPrice(item.desconto_suframa)}
              </td>
              <td
                hidden={invoice.hide_total_liquid_ipi || order.empresa === 3}
                style={{ textAlign: "center" }}
              >
                {formatPrice(item.price_liquid_ipi)}
              </td>
              <td style={{ textAlign: "center" }}>
                <b>{formatPrice(item.price_total_tax)}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceItem;
