import React from "react";
import { Text } from "@react-pdf/renderer";

interface INoStockTag {
  value: number;
  stock: number;
  amount?: number;
}

type IColor = "warning";

interface ILabel {
  color: IColor;
  title: string;
}

const Label: React.FC<ILabel> = ({ color, title }) => {
  const colors = { warning: "#ff9800" };

  return (
    <Text
      style={{
        justifyContent: "flex-end",
        fontSize: 4,
        marginLeft: "4px",
        borderRadius: "2px",
        padding: "1.4px",
        color: "#FFFFFF",
        backgroundColor: colors[color],
      }}
    >
      {title}
    </Text>
  );
};

export const NoStockTag: React.FC<INoStockTag> = ({ value, stock, amount }) => {
  if (value === 1) {
    if (amount! > stock) {
      return <Label color="warning" title="Sob Encomenda" />;
    }
  }

  if (stock <= 0) {
    return <Label color="warning" title="Sem Estoque" />;
  }

  if (amount! > stock) {
    return <Label color="warning" title="Estoque Insuficiente" />;
  }

  return null;
};
