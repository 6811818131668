import React from "react";

import { IOrder } from "@interfaces/order";

interface IInvoicePriceDetail {
  order: IOrder;
}

const InvoiceFooter: React.FC<IInvoicePriceDetail> = ({ order }) => {
  return (
    <>
      <p className="text-center mb-1 fw-600">OBRIGADO PELA PREFERÊNCIA</p>
      <p className="text-center">
        <span className="me-1">
          <i className="fa fa-fw fa-lg fa-globe" />
          <a
            href={
              order.empresa === 3
                ? "www.indipeso.com.br "
                : "www.sibratec.ind.br "
            }
          >
            {" "}
            {order.empresa === 3
              ? "www.indipeso.com.br "
              : "www.sibratec.ind.br "}
          </a>
        </span>
        <span className="me-1">
          <i className="fa fa-fw fa-lg fa-phone-volume" />
          {` ${order.sales_man ? order.sales_man.telefone : ""}`}
        </span>
        <span className="me-1">
          <i className="fa fa-fw fa-lg fa-envelope" />
          {` ${order.sales_man ? order.sales_man.email : ""}`}
        </span>
      </p>
    </>
  );
};

export default InvoiceFooter;
