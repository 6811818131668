import React from "react";
import { Row, Col } from "reactstrap";
import { formatCnpjCpf } from "@brainylab/brazilian-formats";

import { format, parseISO } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

import { IOrder } from "@interfaces/order";

import { useBudget } from "@modules/invoices/hooks/useBudget";

import logoIndipeso from "../../../assets/img/logo/indipeso.png";
import logoRealPower from "../../../assets/img/logo/JVA.png";
import logoSvg from "../../../assets/img/logo/logo-sibratec.svg";

interface IInvoiceDetail {
  order: IOrder;
}

const logo: any = {
  1: logoSvg,
  2: logoRealPower,
  3: logoIndipeso,
};

const InvoiceDetail: React.FC<IInvoiceDetail> = ({ order }) => {
  const isComplement = false;
  const isBudget = useBudget(order.status);

  return (
    <Row>
      <Col hidden={order.empresa === 0}>
        <address className="mt-1 mb-1">
          <img
            src={order.empresa ? logo[order.empresa] : logoSvg}
            style={{ width: "40%" }}
            alt="logo"
            className="pb-2"
          />
          <br />
          <strong>{order.company && order.company.name}</strong>
          <br />
          {order.company && order.company.address}
          {`, `}
          {order.company && order.company.number}
          {` - `}
          {order.company && order.company.neighborhood}
          <br />
          {order.company && order.company.city}
          {` - `}
          {order.company && order.company.district}
          {` - `}
          {order.company && order.company.country}
          {`. CEP: `}
          {order.company && order.company.zipcode}
          <br />
          <b>Telefone: </b>
          {order.company &&
            `+55 ${order.company.telephone} / ${order.company.second_telephone}`}
          <br />
          <b>CNPJ: </b>
          {order.company && formatCnpjCpf(String(order.company.cnpj))}
          {!order.done_ecommerce && (
            <>
              {" "}
              <br />
              <b>Representante: </b>
              {order.sales_man ? order.sales_man.nome : ""}
              {` `}
              <b>Email: </b>
              {order.sales_man ? order.sales_man.email : ""}
              <br />
              <b>Telefone: </b>
              {order.sales_man ? order.sales_man.telefone : ""}
            </>
          )}
        </address>
      </Col>
      <Col>
        <div className="text-end">
          <span className="fw-700 fs-5 text-uppercase">
            {order.updated_at
              ? format(
                  parseISO(String(order.updated_at)),
                  " d 'de' MMMM 'de' yyyy",
                  {
                    locale: ptBr,
                  }
                )
              : order.data_alt}
          </span>
          <br />
          <span className={`fw-600 ${isComplement && "text-danger"}`}>
            {`${isBudget ? "ORÇAMENTO" : "PEDIDO"} ${
              isComplement ? "COMPLEMENTAR" : ""
            } - ${order.id}`}
          </span>
          <br />
          <b>Cliente: </b>
          {order.client ? `${order.client.codigo} - ${order.client.razao}` : ""}
          <br />
          <b>Responsável: </b>
          {order.client ? order.client.responsavel : ""}
          {" / "}
          <b>E-mail: </b>
          {order.client ? order.client.email : ""}
          <br />
          <b>Cidade: </b>
          {order.client ? order.client.cidade : ""}
          {" / "}
          <b>Estado: </b>
          {order.client ? order.client.district.name : ""}
          <br />
          <b>Endereço: </b>
          {order.client ? order.client.endereco : ""}
          {" / "}
          <b>Nº: </b>
          {order.client ? order.client.numero : ""}
          <br />
          <b>Bairro: </b>
          {order.client ? order.client.bairro : ""}
          {" / "}
          <b>CEP: </b>
          {order.client ? order.client.cep : ""}
          <br />
          <b>CNPJ: </b>
          {order.client ? formatCnpjCpf(order.client.cnpj) : ""}
          {" / "}
          <b>IE: </b>
          {order.client ? order.client.ie : ""}
          <br />
          <b>Telefone: </b>
          {order.client ? order.client.telefone : ""}
          {order.client ? ` / ${order.client.telefone2}` : ""}
          <br />
          <b>Tipo de Tributação: </b>
          {order.client ? order.client.taxation_type.descricao : ""}
          <br />
        </div>
      </Col>
    </Row>
  );
};

export default InvoiceDetail;
