import React from "react";
import { RouteObject } from "react-router-dom";

import InvoiceDetail from "./pages/Invoice";

export const InvoicesRoutes: Array<RouteObject> = [
  {
    path: "/:key",
    element: <InvoiceDetail />,
  },
];
