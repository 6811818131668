import { IProductStocks } from "@interfaces/product";

interface ICalculateStock {
  stocks: Array<IProductStocks>;

  company?: number;
  limitedStock?: boolean;
}

function stockLimited(stock: number, limit: number): number {
  if (stock < 0) {
    return 0;
  }

  const newStock = stock - stock * (limit / 100);

  return newStock;
}

function validateNegativeStock(stock: number): number {
  if (stock < 0) return 0;

  return Math.round(stock);
}

function calculateStock({
  stocks,
  company,
  limitedStock,
}: ICalculateStock): number {
  try {
    const userLimited = false;
    const companies: { [key: number]: boolean } = {
      1: true,
      2: true,
      3: true,
    };

    if (!company) {
      const stock = stocks.map((item) => {
        if (companies[item.company]) {
          const totalStock = item.stock - item.stock_reserved;
          return userLimited
            ? validateNegativeStock(stockLimited(totalStock, item.sell_limit))
            : validateNegativeStock(totalStock);
        }

        return 0;
      });

      if (stock.length === 0) {
        return 0;
      }

      return validateNegativeStock(
        stock.reduce((total, item) => {
          return total + item;
        })
      );
    }

    const itemStock = stocks.find(
      (item) => item.company === company
    ) as IProductStocks;

    if (!itemStock || itemStock.some_stock) {
      const stockTotal = itemStock.stock - itemStock.stock_reserved;

      const stockSome = stocks.map((otherStock) => {
        if (company !== otherStock.company) {
          return otherStock.stock - otherStock.stock_reserved;
        }

        return 0;
      });

      const someTotal = stockSome.reduce((total, iv) => {
        return total + iv;
      });

      if (limitedStock || userLimited)
        return validateNegativeStock(
          stockLimited(stockTotal + someTotal, itemStock.sell_limit)
        );

      return !userLimited
        ? validateNegativeStock(stockTotal)
        : validateNegativeStock(stockTotal + someTotal);
    }

    return limitedStock || userLimited
      ? validateNegativeStock(
          stockLimited(
            itemStock.stock - itemStock.stock_reserved,
            itemStock.sell_limit
          )
        )
      : validateNegativeStock(itemStock.stock - itemStock.stock_reserved);
  } catch {
    return 0;
  }
}

export { calculateStock, stockLimited, validateNegativeStock };
