import React from "react";
import { Text, View } from "@react-pdf/renderer";

import { IOrder } from "@interfaces/order";

import { InvoicePDFNote } from "./InvoicePDFNote";

interface IHeaderPDF {
  order: IOrder;
}

export const InvoicePDFFooter: React.FC<IHeaderPDF> = ({ order }) => {
  return (
    <View style={{ flex: 1, justifyContent: "flex-end" }}>
      <InvoicePDFNote order={order} />
      <View style={{ borderTopWidth: 0.4, borderTopColor: "#383838" }} />
      <View style={{ alignItems: "center", color: "black", marginTop: 5 }}>
        <Text>OBRIGADO PELA PREFERÊNCIA</Text>
      </View>
      <View
        style={{
          color: "black",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text style={{ color: "red" }}>
            {order.empresa === 3
              ? "www.indipeso.com.br "
              : "www.sibratec.ind.br "}
          </Text>
          <Text>
            | {` ${order.sales_man && order.sales_man.telefone}`} |{" "}
            {` ${order.sales_man && order.sales_man.email}`}
          </Text>
        </View>
      </View>
    </View>
  );
};
